const firebaseConfig = {
  apiKey: 'AIzaSyCCFtP7muN7n2YqVVNfijBoGCq2CTl2eHM',
  authDomain: 'pericope.app',
  projectId: 'sermonlinks-8a00d',
  storageBucket: 'sermonlinks-8a00d.appspot.com',
  messagingSenderId: '183044878425',
  appId: '1:183044878425:web:daa4e49308647132ef8148',
  measurementId: 'G-S549E9GVEN',
};

export { firebaseConfig };
